<template>
  <div>
    <section class="product-list-heading">
      <div class="container">
        <div class="breadcrumb m-0">
          <router-link to="/">Home</router-link>
          <i class="icon-next"></i>
          <span>{{ getBreadcrumb() }}</span>
        </div>
        <!-- // TODO dynamic announcement banner -->
        <UrlHandler v-if="announcementBanner" class="d-lg-none" :url="announcementBanner.url">
          <b-img-lazy :src="announcementBanner.image_mobile_url" :title="announcementBanner.name" :alt="announcementBanner.name" class="mb-1" fluid />
        </UrlHandler>
      </div>
    </section>
    <template v-if="activeBanner">
      <section class="product-list m-0 d-none d-lg-block">
        <div class="row">
          <div class="col p-0 text-center">
            <b-img-lazy fluid
              :src="activeBanner.img || activeBanner.category_img"
              :alt="activeBanner?.img_alt || ''"
            />
          </div>
        </div>
      </section>
      <section class="product-list m-0 d-lg-none">
        <div class="row">
          <div class="col p-0 text-center">
            <b-img-lazy fluid
              :src="activeBanner.mobile_img || activeBanner.category_img"
              :alt="activeBanner?.img_alt || ''"
            />
          </div>
        </div>
      </section>
    </template>
    <section
      v-else-if="hideBrandsFilter && $route.params.brand && brands.length"
      class="product-list mb-4"
    >
      <div class="container" v-if="selectedBrand">
        <div class="store-closed-box store-closed-box--brand mb-3" id="store-closed-shop-info-brand" v-if="selectedBrand?.merchant_info?.is_closed">
          <fawesome-pro variant="far" icon="exclamation-triangle" size="lg" class="store-closed-notes-info px-2" />
          <div class="ml-2">
            <b>Toko sedang tutup</b>
            <div class="mt-1">Hey, barang kamu akan di proses pada saat toko buka tanggal <b>{{ formatEndDateClose(selectedBrand.merchant_info.end_date_close) }}</b> ya! <b class="store-closed-notes-info" v-b-modal.modal-closed-shop-info-brand v-if="selectedBrand?.merchant_info?.closed_notes">Selengkapnya</b></div>
          </div>
          <fawesome-pro variant="far" icon="times" class="cursor-pointer align-self-start ml-auto pr-1 pt-lg-1" @click="hideClosedShopInfo" />
          <!-- <button class="btn-close-modal-custom" @click="$bvModal.hide('modal-review-product')">&times;</button> -->
        </div>
        <div class="brand-banner">
          <div class="banner-brand-logo-container">
            <div class="banner-brand-logo-new bg-white" :style="{'--background' : `url('${selectedBrand.image_banner_square}')`}">
              <div class="banner-brand-logo-prime" v-if="selectedBrand.check_prime_merchant"></div>
            </div>
          </div>
          <div class="brand-info ml-3 text-white">
            <div class="bold">{{ `${selectedBrand.name.toUpperCase()} OFFICIAL STORE` }}</div>
            <div class="banner-brand-description mt-1">
              <div class="banner-brand-rating" v-if="selectedBrand.average_brand_rating > 0" @click="navBarReview != 'active' ? changeNavbarByBrand('review') : null">
                <fawesome-pro variant="fas" icon="star" class="text-warning pr-1" />
                <span>{{ selectedBrand.average_brand_rating.toFixed(1) }}/5</span>
              </div>
              <div v-if="selectedBrand.operational_time" @click="changeNavbarByBrand('info'); scrollIntoOpTime()" class="d-flex mt-1 mt-lg-0">
                <b-img width="12" :src="require('@/assets/img/icon_time_white.svg')" alt="" class="icon-time-white-color"></b-img>
                <span class="ml-1">{{ setOperationalTimeText(getTodayOperationalTime(selectedBrand.operational_time)) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- HTML content for collection & brand -->
    <div class="container clearfix my-2" v-if="activeContent">
      <div class="d-none d-lg-block ck-content" v-html="activeContent.desktop" v-if="activeContent.desktop"></div>
      <div class="d-lg-none d-block ck-content" v-html="activeContent.mobile" v-if="activeContent.mobile"></div>
    </div>

    <!-- Navgation for shop by brand -->
    <nav v-if="hideBrandsFilter && $route.params.brand && brands.length">
      <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
        <div
          class="nav-item nav-link cursor-pointer"
          :class="navBarProduct"
          id="nav-product-tab"
          @click="navBarProduct != 'active' ? changeNavbarByBrand('product') : null"
        >
          Products
        </div>
        <div
          class="nav-item nav-link cursor-pointer"
          :class="navBarCollection"
          id="nav-collection-tab"
          @click="navBarCollection != 'active' ? changeNavbarByBrand('collection') : null"
        >
          Collections
        </div>
        <div
          class="nav-item nav-link cursor-pointer"
          :class="navBarReview"
          id="nav-review-tab"
          @click="navBarReview != 'active' ? changeNavbarByBrand('review') : null"
        >
          Reviews
        </div>
        <div
          class="nav-item nav-link cursor-pointer"
          :class="navBarInfo"
          id="nav-info-tab"
          @click="navBarInfo != 'active' ? changeNavbarByBrand('info') : null"
        >
          Info
        </div>
      </div>
    </nav>
    <!-- Product List -->
    <section class="product-list product-grey" v-if="navBarProduct">
      <div class="floating-filter d-lg-none" :class="{'more' : $store.getters.getTopmenu.find(el => el.show_type == 'MARQUEE')}">
        <div class="floating-filter-body">
          <div>
            <fawesome-pro variant="fal" icon="sort-alt" /> Sort
            <select v-model="sortMask" @input="setSort($event)">
              <option value="popular">Popular</option>
              <option value="discount">Discount</option>
              <option value="lowest_price">Lowest Price</option>
              <option value="highest_price">Highest Price</option>
              <option value="latest">Latest</option>
              <option value="name_asc">Name A-Z</option>
              <option value="name_desc">Name Z-A</option>
            </select>
          </div>
          <div v-if="!hideAllFilters" class="border-left" v-b-modal.modal-sort-filter>
            <fawesome-pro variant="fal" icon="sliders-h" /> Filter
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <template v-if="!hideAllFilters">
            <div class="col-2 d-none d-lg-block">
              <PuSkeleton v-if="isLoading.sideBar" height="300px" />
              <template v-else>
                <p>Filter by</p>
                <div v-if="!hideBrandsFilter" class="panel-filter">
                  <h4 @click="collapseBrands = !collapseBrands">
                    Brand
                    <i class="icon-prev" :class="{ collapsed: collapseBrands }"></i>
                  </h4>
                  <ul
                    class="panel-filter-body"
                    v-if="!collapseBrands"
                  >
                    <li>
                      <div class="styled-checkbox">
                        <label class="container-checkbox" @click="selectAllBrand"
                          >All Brands
                        </label>
                      </div>
                    </li>
                    <li v-for="(brand, index) in brands" :key="index">
                      <div class="styled-checkbox">
                        <label class="container-checkbox">
                          {{ brand.name }}
                          <input
                            :id="brand.id"
                            :value="brand.id"
                            name="brandId"
                            type="checkbox"
                            v-model="checkedBrands"
                          />
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-if="!hideCollectionsFilter" class="panel-filter">
                  <h4 @click="collapseCollections = !collapseCollections">
                    Collections
                    <i
                      class="icon-prev"
                      :class="{ collapsed: collapseCollections }"
                    ></i>
                  </h4>
                  <ul
                    class="panel-filter-body"
                    v-if="!collapseCollections"
                  >
                    <li>
                      <div class="styled-checkbox">
                        <label
                          class="container-checkbox"
                          @click="selectAllCollections"
                          >All Collections
                        </label>
                      </div>
                    </li>
                    <li v-for="(collection, index) in collections" :key="index">
                      <div class="styled-checkbox">
                        <label class="container-checkbox">
                          {{ collection.name }}
                          <input
                            :id="collection.id"
                            :value="collection.id"
                            name="brandId"
                            type="checkbox"
                            v-model="checkedCollections"
                          />
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-if="!hideCategoriesFilter" class="panel-filter">
                  <h4 @click="collapseCategories = !collapseCategories">
                    Categories
                    <i
                      class="icon-prev"
                      :class="{ collapsed: collapseCategories }"
                    ></i>
                  </h4>
                  <ul
                    v-if="!collapseCategories"
                    class="panel-filter-body"
                  >
                    <li>
                      <div class="styled-checkbox">
                        <label class="container-checkbox" @click="selectAllCategory"
                          >All Categories
                        </label>
                      </div>
                    </li>
                    <template v-for="(category, i) in categories">
                      <template v-for="(cat, j) in category.sub_category">
                        <li
                          v-for="(subCat, k) in cat.sub_category"
                          :key="'categoryFilter' + i + j + k"
                        >
                          <div class="styled-checkbox">
                            <label class="container-checkbox"
                              >{{ subCat.name }}
                              <input
                                :id="subCat.id"
                                :value="subCat.id"
                                name="categoryId"
                                type="checkbox"
                                v-model="checkedCategories"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
                <div class="panel-filter">
                  <h4 @click="collapseColors = !collapseColors">
                    Color
                    <i class="icon-prev" :class="{ collapsed: collapseColors }"></i>
                  </h4>
                  <ul
                    class="panel-filter-body"
                    v-if="!collapseColors"
                  >
                    <li>
                      <div class="styled-checkbox">
                        <label class="container-checkbox" @click="selectAllColors"
                          >All Colors
                        </label>
                      </div>
                    </li>
                    <li v-for="(color, i) in colors" :key="i">
                      <div class="styled-checkbox">
                        <label class="container-checkbox"
                          >{{ color.name }}
                          <input
                            :id="color.id"
                            :value="color.id"
                            name="colorId"
                            type="checkbox"
                            v-model="checkedColors"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="panel-filter">
                  <h4 @click="collapseSize = !collapseSize">
                    Size
                    <i class="icon-prev" :class="{ collapsed: collapseSize }"></i>
                  </h4>
                  <ul class="panel-filter-body" v-if="!collapseSize">
                    <li>
                      <div class="styled-checkbox">
                        <label class="container-checkbox" @click="selectAllSizes"
                          >All Sizes
                        </label>
                      </div>
                    </li>
                    <li v-for="(size, i) in sizes" :key="i">
                      <div class="styled-checkbox">
                        <label class="container-checkbox"
                          >{{ size.international }}
                          <input
                            :id="size.id"
                            :value="size.id"
                            name="sizeId"
                            type="checkbox"
                            v-model="checkedSizes"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-if="!hidePriceFilter" class="panel-filter">
                  <h4>Price</h4>
                  <div class="panel-filter-body">
                    <div class="row m-0">
                      <div class="col p-0">
                        <input
                          id="min-price-separator"
                          type="text"
                          class="form-control"
                          placeholder="Min"
                          v-model="minPrice"
                          @input="priceSeparator('minPrice')"
                          @keydown.enter="applyFilter(); $helpers.scroll.scrollToTop()"
                        />
                      </div>
                      <div class="col p-0">
                        <input
                          id="max-price-separator"
                          type="text"
                          class="form-control"
                          placeholder="Max"
                          v-model="maxPrice"
                          @input="priceSeparator('maxPrice')"
                          @keydown.enter="applyFilter(); $helpers.scroll.scrollToTop()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  @click="applyFilter(); $helpers.scroll.scrollToTop()"
                  class="btn btn-wz-proceed btn-block my-3"
                >
                  APPLY FILTER
                </button>
                <button
                  @click="resetFilter"
                  class="btn btn-wz-transparent-v2 btn-block my-3"
                  :disabled="resetFilterDisabledState()"
                >
                  RESET FILTER
                </button>
              </template>
            </div>
          </template>
          <div class="col-md-10">
            <!-- // TODO dynamic announcement banner -->
            <UrlHandler v-if="announcementBanner" class="d-none d-lg-block pb-4" :url="announcementBanner.url">
              <b-img-lazy :src="announcementBanner.image_url" :title="announcementBanner.name" :alt="announcementBanner.name" fluid-grow />
            </UrlHandler>
            <!-- <PuSkeleton v-if="isLoading.productList" height="600px" /> -->
            <div class="d-grid mb-2" v-if="isLoading.productList">
              <div class="cell-1x1 p-1" v-for="i in 8" :key="`list-product-box-skeleton-${i}`">
                <ProductBoxSkeleton />
              </div>
            </div>
            <template v-else>
              <div class="row horizontal-filter justify-content-between" v-if="($route.path.includes('/promo/') && detailPromo?.status == 1) || products.length > 0">
                <div class="col-md-3">
                  <select
                    id="sort"
                    class="form-control select-custom d-none d-lg-block"
                    v-model="sortMask"
                    @input="setSort($event)"
                  >
                    <option value="popular">Popular</option>
                    <option value="discount">Discount</option>
                    <option value="lowest_price">Lowest Price</option>
                    <option value="highest_price">Highest Price</option>
                    <option value="latest">Latest</option>
                    <option value="name_asc">Name A-Z</option>
                    <option value="name_desc">Name Z-A</option>
                  </select>
                </div>
                <div class="col-md-9 text-lg-right">
                  <p class="m-0">
                    Showing
                    <span class="text-gold">
                      {{
                        elasticLimit * (currentPage - 1) + 1
                      }}–{{elasticLimit * currentPage &lt;= totalResult ? elasticLimit * currentPage : totalResult}}
                    </span>
                    of {{ totalResult }} results
                  </p>
                </div>
              </div>
              <div class="d-grid">
                <!-- <div v-if="$route.params.slug == 'promo' && [0, 2].includes(detailPromo?.status)" class="cell-full text-center">
                  <img
                    src="@/assets/img/page-not-found.png"
                    alt="Product not found"
                  />
                  <div class="my-3">
                    <template v-if="detailPromo.status === 0">Tunggu ya! Promonya akan dimulai pada tanggal <b>{{ $helpers.format.epochMsToLocalTime(detailPromo.active_from_epoch) }}</b></template>
                    <template v-else-if="detailPromo.status === 2">Mohon maaf :( Promo sudah berakhir pada tanggal <b>{{ $helpers.format.epochMsToLocalTime(detailPromo.active_to_epoch) }}</b> </template>
                  </div>
                  <router-link to="/promo" class="btn btn-wz-proceed rounded mb-3">
                    Yuk! Cek promo lainnya
                  </router-link>
                </div>
                <div v-else-if="!hasResult && !isLoading.productList" class="cell-full text-center">
                  <img
                    src="@/assets/img/page-not-found.png"
                    alt="Product not found"
                  />
                  <template v-if="/\/search\//.test($route.path)">
                    <div class="mt-3">Oops, hasil pencarian <b>"{{ $route.params.keyWord }}"</b> tidak ditemukan</div>
                    <div>Silahkan coba <b class="cursor-pointer hover-gold" @click="setFocusSearch">kata kunci yang lain</b> atau cek produk terbaru kita</div>
                    <NewArrival />
                  </template>
                  <template v-else>
                    <div class="my-3">{{ getNoResultText() }}</div>
                    <router-link to="/promo" class="btn btn-wz-proceed rounded mb-3" v-if="$route.path.includes('/promo/')">
                      Yuk! Cek promo lainnya
                    </router-link>
                    <router-link to="/find-your-style" class="btn btn-wz-proceed rounded mb-3" v-if="$route.path.includes('/collection/')">
                      Yuk! Cek koleksi produk lainnya
                    </router-link>
                  </template>
                </div> -->
                <!-- Handling Not Found / Empty Data -->
                <div class="cell-full text-center" v-if="$route.path.includes('/promo/') && (!detailPromo || [0, 2].includes(detailPromo?.status))">
                  <img src="@/assets/img/page-not-found.png" alt="Page not found" title="page-not-found" />
                  <div class="my-3" v-if="!detailPromo">Oops, promo yang kamu cari tidak ada.</div>
                  <template v-else-if="[0, 2].includes(detailPromo?.status)">
                    <div class="my-3">
                      <template v-if="detailPromo.status === 0">Tunggu ya! Promonya akan dimulai pada tanggal <b>{{ $helpers.format.epochMsToLocalTime(detailPromo.active_from_epoch) }}</b></template>
                      <template v-else-if="detailPromo.status === 2">Mohon maaf :( Promo sudah berakhir pada tanggal <b>{{ $helpers.format.epochMsToLocalTime(detailPromo.active_to_epoch) }}</b> </template>
                    </div>
                  </template>
                  <router-link to="/promo" class="btn btn-wz-proceed rounded mb-3">
                    Yuk! Cek promo lainnya
                  </router-link>
                </div>
                <div class="cell-full text-center" v-else-if="products.length == 0">
                  <img src="@/assets/img/page-not-found.png" alt="Page not found" title="page-not-found" />
                  <template v-if="$route.path.includes('/search/')">
                    <div class="mt-3">Oops, hasil pencarian <b>"{{ $route.params.keyWord }}"</b> tidak ditemukan</div>
                    <div>Silahkan coba <b class="cursor-pointer hover-gold" @click="setFocusSearch">kata kunci yang lain</b> atau cek produk terbaru kita</div>
                    <NewArrival />
                  </template>
                  <div class="my-3" v-else>Sorry, there is no result</div>
                </div>
                <template v-else v-for="(product, i) in products">
                  <template v-for="(highlight, j) in highlights">
                    <div class="cell-2x2"  v-if="[0, 10, 16].indexOf(i) == j" :key="`highlight-${i}${j}`">
                      <UrlHandler :url="highlight.link_url || (product => product ? `${feUrl}product/${product.slug}` : null)(highlight.product)">
                        <b-img-lazy fluid 
                          class="rounded" 
                          :src="highlight.image_url || highlight.product 
                            && (img => img.raw_image_url ? `${img.raw_image_url}?w=1024` : img.full_image_url)
                            (highlight.product.product_images[0])
                          "
                          :alt="setHighlightProductImgAltText(highlight)"
                          @error.native="replaceByDefaultImage"
                        />
                      </UrlHandler>
                    </div>
                  </template>
                  <div class="cell-1x1 p-1" :key="i">
                    <ProductBox :product="product" detailOnHover @showModalCategorySize="categorySize = $event" @showModalInstallmentCalculator="showModalInstallmentCalculator" />
                  </div>
                </template>
              </div>
              <div
                class="d-flex justify-content-end"
                v-if="totalResult > 0"
              >
                <b-pagination
                  @change="pageChange"
                  v-model="currentPage"
                  :total-rows="totalResult"
                  :per-page="elasticLimit"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <!-- Collection per Brand -->
    <section class="product-list product-grey mb-4" v-if="navBarCollection">
      <div class="container">
        <!-- //? Skeleton loading still not working, since fetching the data isn't using async function -->
        <PuSkeleton height="200px" :count="1" v-if="isLoading.brandCollectionList" />
        <div v-else-if="!brandCollection.length" class="col-12 text-center">
          <img
            width="300px"
            height="300px"
            src="@/assets/img/page-not-found.png"
            alt="Collection not found"
          />
          <div class="mt-4 mb-0"><b>Sorry, there is no result</b></div>
        </div>
        <div class="row pt-3 pt-lg-0 px-3 px-md-5 justify-content-start">
          <div class="col-12 col-md-6 mb-3" v-for="collection in brandCollection" :key="collection.id">
            <ProductCollectionCard :collection="collection" />
          </div>
        </div>
      </div>
    </section>

    <!-- Review per Brand -->
    <section class="product-list product-grey mb-4" v-if="navBarReview">
      <div class="container">
        <PuSkeleton height="200px" :count="3" v-if="isLoading.brandReviewList" />
        <div v-else-if="!brandReview.length" class="col-12 text-center">
          <img
            width="300px"
            height="300px"
            src="@/assets/img/empty-review.png"
            alt="Review not found"
          />
          <div class="mt-4 mb-0"><b>Sorry, there is no result</b></div>
        </div>
        <div
          v-else
          v-for="(review, brandIndex) in brandReview"
          :key="brandIndex"
          class="card mt-3"
        >
          <!-- TODO Rework html slicing product review list -->
          <!-- Brand Product Review Card Start-->
          <ul class="list-group list-group-flush">
            <li
              class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                py-4
              "
            >
              <div class="container p-0">
                <div class="row">
                  <div class="col-md-12 mb-1">
                    <router-link :to="`/product/${review.product.slug}`">
                      <img
                        class="float-left rounded border mr-3"
                        :src="review.product.image_url"
                        :alt="setProductImgAltText(review.product)"
                        width="150px"
                        height="150px"
                      />
                    </router-link>
                    <p class="mb-1">
                      {{ review.product.brand_name }}
                    </p>
                    <router-link :to="`/product/${review.product.slug}`" class="text-black bold">{{ review.product.name }}</router-link>
                    <!-- Rating Start -->
                    <div>
                      <fawesome-pro
                        v-for="i in 5" :key="i"
                        :variant="review.rating - i > -1 ? 'fas' : 'far'"
                        icon="star"
                        class="text-warning pr-1"
                      />
                    </div>
                    <!-- Rating End -->
                    <p class="mb-0">
                      {{ formatDate(review.created_at) }}
                    </p>
                  </div>
                  <!-- Comment & Tag Section Start -->
                  <div class="col-md-12">
                    <!-- Comment Start -->
                    <p class="my-1" v-if="review.comment">
                      {{ review.comment.trim() }}
                    </p>
                    <!-- Comment End -->
                    <!-- Tag(s) Start -->
                    <div class="mt-0 mb-1" v-if="review.tag != ('' || null)">
                      <template v-for="(tag, index) of review.tag.split(';')">
                        <div
                          class="chip mt-2 ml-0 mr-2"
                          style="cursor: unset"
                          v-if="tag.trim()"
                          :key="index"
                        >
                          {{ tag }}
                        </div>
                      </template>
                    </div>
                    <!-- Tag(s) End -->
                    <!-- Review Image(s) Start -->
                    <div
                      class="row mt-3 mb-4 px-2"
                      style="flex-wrap: unset"
                      v-if="review.review_images != ''"
                    >
                      <div
                        class="col p-0 pl-1 pull-left"
                        style="max-width: 100px; max-height: 100px"
                        :key="index"
                        v-for="(image, index) in review.review_images"
                      >
                        <img
                          :src="image"
                          alt=""
                          class="rounded"
                          style="
                            border-radius: 15px;
                            width: 100% !important;
                            border: 2px solid #f1f1f1;
                            cursor: pointer;
                          "
                          v-b-modal="'modal-image'"
                          @click="sendDataToModal(review.review_images, index)"
                        />
                      </div>
                    </div>
                    <template v-if="review.review_feedbacks.length">
                      <div class="col-md-6 mt-4 mb-4 merchant-feedback" v-for="(feedback, i) in review.review_feedbacks" :key="i">
                        <div class="merchant-profile">
                          <img src="@/assets/img/my-profile-default.png" alt="">
                        </div>
                        <div class="info ml-2">
                          <b class="text-gold">{{ review.product.seller.name }}</b>
                          <div class="time">{{ formatDate(feedback.created_at) }}</div>
                          <div>{{ feedback.comment }}</div>
                        </div>
                      </div>
                    </template>
                    <!-- Review Image(s) End -->
                    <p class="mb-0">
                      Reviewer :
                      <span class="bold">
                        {{ review.reviewer_name }}
                      </span>
                    </p>
                  </div>
                  <!-- Comment & Tag Section End -->
                </div>
              </div>
            </li>
          </ul>
          <!-- Brand Product Review Card End -->
        </div>
        <div
          class="row mt-3 d-flex justify-content-end"
          v-if="totalReviewResult > 0"
        >
          <div class="col-auto text-center">
            <b-pagination
              @change="pageChange"
              v-model="currentPageReview"
              :total-rows="totalReviewResult"
              :per-page="5"
            ></b-pagination>
          </div>
        </div>
      </div>
    </section>

    <!-- Info Brand -->
    <section class="product-list product-grey" v-if="navBarInfo">
      <div class="container">
        <div>
          <p class="bold">Brand Information</p>
          <p class="text-grey">{{ selectedBrand.name }}</p>
          <template v-if="selectedBrand.description_banner">
            <hr />
            <p class="bold">Description</p>
            <p class="text-justify text-grey">
              {{ selectedBrand.description_banner }}
            </p>
          </template>
          <template v-if="selectedBrand.operational_time">
            <hr />
            <div class="bold" id="merchant-op-time">Operational Time</div>
            <div class="d-flex align-items-center my-2">
              <b-img width="40" class="mx-lg-2 mx-3" :src="require('@/assets/img/icon_time_warning.svg')" alt=""></b-img>
              <span class="text-grey">Pesanan kamu berpontensi mengalami keterlambatan pengiriman jika diluar jam operasional.</span>
            </div>
            <div v-for="(opTime, index) in selectedBrand.operational_time" :key="index" class="operational-time-list">
              <span>{{ opTime.name }}</span>
              <span class="float-right">{{ setOperationalTimeText(opTime) }}</span>
            </div>
          </template>
          <br>
        </div>
      </div>
    </section>
    <b-modal id="modal-sort-filter" scrollable centered>
      <template #modal-header>
        <div class="d-flex w-100 align-items-center justify-content-between">
          Filter by
          <font-awesome icon="times" class="cursor-pointer" @click="$bvModal.hide('modal-sort-filter')" />
        </div>
      </template>
      <template>
        <div v-if="!hideBrandsFilter" class="panel-filter">
          <h4 @click="collapseBrands = !collapseBrands">
            Brand
            <i class="icon-prev" :class="{ collapsed: collapseBrands }"></i>
          </h4>
          <ul
            class="panel-filter-body"
            v-if="!collapseBrands"
          >
            <li>
              <div class="styled-checkbox">
                <label class="container-checkbox" @click="selectAllBrand"
                  >All Brands
                </label>
              </div>
            </li>
            <li v-for="(brand, index) in brands" :key="index">
              <div class="styled-checkbox">
                <label class="container-checkbox">
                  {{ brand.name }}
                  <input
                    :id="brand.id"
                    :value="brand.id"
                    name="brandId"
                    type="checkbox"
                    v-model="checkedBrands"
                  />
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="!hideCollectionsFilter" class="panel-filter">
          <h4 @click="collapseCollections = !collapseCollections">
            Collections
            <i
              class="icon-prev"
              :class="{ collapsed: collapseCollections }"
            ></i>
          </h4>
          <ul
            class="panel-filter-body"
            v-if="!collapseCollections"
          >
            <li>
              <div class="styled-checkbox">
                <label
                  class="container-checkbox"
                  @click="selectAllCollections"
                  >All Collections
                </label>
              </div>
            </li>
            <li v-for="(collection, index) in collections" :key="index">
              <div class="styled-checkbox">
                <label class="container-checkbox">
                  {{ collection.name }}
                  <input
                    :id="collection.id"
                    :value="collection.id"
                    name="brandId"
                    type="checkbox"
                    v-model="checkedCollections"
                  />
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="!hideCategoriesFilter" class="panel-filter">
          <h4 @click="collapseCategories = !collapseCategories">
            Categories
            <i
              class="icon-prev"
              :class="{ collapsed: collapseCategories }"
            ></i>
          </h4>
          <ul
            v-if="!collapseCategories"
            class="panel-filter-body"
          >
            <li>
              <div class="styled-checkbox">
                <label class="container-checkbox" @click="selectAllCategory"
                  >All Categories
                </label>
              </div>
            </li>
            <template v-for="(category, i) in categories">
              <template v-for="(cat, j) in category.sub_category">
                <li
                  v-for="(subCat, k) in cat.sub_category"
                  :key="'categoryFilter' + i + j + k"
                >
                  <div class="styled-checkbox">
                    <label class="container-checkbox"
                      >{{ subCat.name }}
                      <input
                        :id="subCat.id"
                        :value="subCat.id"
                        name="categoryId"
                        type="checkbox"
                        v-model="checkedCategories"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </li>
              </template>
            </template>
          </ul>
        </div>
        <div class="panel-filter">
          <h4 @click="collapseColors = !collapseColors">
            Color
            <i class="icon-prev" :class="{ collapsed: collapseColors }"></i>
          </h4>
          <ul
            class="panel-filter-body"
            v-if="!collapseColors"
          >
            <li>
              <div class="styled-checkbox">
                <label class="container-checkbox" @click="selectAllColors"
                  >All Colors
                </label>
              </div>
            </li>
            <li v-for="(color, i) in colors" :key="i">
              <div class="styled-checkbox">
                <label class="container-checkbox"
                  >{{ color.name }}
                  <input
                    :id="color.id"
                    :value="color.id"
                    name="colorId"
                    type="checkbox"
                    v-model="checkedColors"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div class="panel-filter">
          <h4 @click="collapseSize = !collapseSize">
            Size
            <i class="icon-prev" :class="{ collapsed: collapseSize }"></i>
          </h4>
          <ul class="panel-filter-body" v-if="!collapseSize">
            <li>
              <div class="styled-checkbox">
                <label class="container-checkbox" @click="selectAllSizes"
                  >All Sizes
                </label>
              </div>
            </li>
            <li v-for="(size, i) in sizes" :key="i">
              <div class="styled-checkbox">
                <label class="container-checkbox"
                  >{{ size.international }}
                  <input
                    :id="size.id"
                    :value="size.id"
                    name="sizeId"
                    type="checkbox"
                    v-model="checkedSizes"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="!hidePriceFilter" class="panel-filter">
          <h4>Price</h4>
          <div class="panel-filter-body">
            <div class="row m-0">
              <div class="col p-0">
                <input
                  id="min-price-separator"
                  type="text"
                  class="form-control"
                  placeholder="Min"
                  v-model="minPrice"
                  @input="priceSeparator('minPrice')"
                />
              </div>
              <div class="col p-0">
                <input
                  id="max-price-separator"
                  type="text"
                  class="form-control"
                  placeholder="Max"
                  v-model="maxPrice"
                  @input="priceSeparator('maxPrice')"
                  @keydown.enter="applyFilter(); $bvModal.hide('modal-sort-filter'); $helpers.scroll.scrollToTop()"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <div class="btn-wz-group m-0 w-100">
          <button
            id="apply-filter"
            class="btn btn-wz-gold btn-block"
            @click="applyFilter(); $bvModal.hide('modal-sort-filter'); $helpers.scroll.scrollToTop()"
          >
            Apply Filter
          </button>
          <button
            id="reset-filter"
            class="btn btn-wz-clear-v2 btn-block"
            @click="resetFilter"
            :disabled="resetFilterDisabledState()"
          >
            Reset Filter
          </button>
        </div>
        <!-- <button
          @click="applyFilter(); $bvModal.hide('modal-sort-filter'); $helpers.scroll.scrollToTop()"
          class="btn btn-wz-gold btn-block"
        >
          APPLY FILTER
        </button> -->
      </template>
    </b-modal>
    <ModalImage
      v-if="reviewImage"
      :items="reviewImage"
      :index="reviewImageIndex"
    />
    <ModalCategorySize :product="categorySize" />
    <ModalInstallmentCalculator :installmentCalculator="installmentCalculator" />
    <ModalClosedShopInfo id="modal-closed-shop-info-brand" :notes="selectedBrand?.merchant_info.closed_notes" v-if="selectedBrand?.merchant_info?.is_closed && selectedBrand?.merchant_info?.closed_notes" />
  </div>
</template>

<script>
import { cookieMan, formatter, setMetainfo, toaster } from "@/_helpers";
import { cmsService } from "@/_services";

const UrlHandler = () => import('@/components/mains/UrlHandler');
const ProductBox = () => import("@/components/mains/ProductBox");
import ModalCategorySize from '@/components/modals/ModalCategorySize';
const NewArrival = () => import("@/components/mains/NewArrival.vue");
import ModalImage from "@/components/modals/ModalImage.vue";
const ModalInstallmentCalculator = () => import("@/components/modals/ModalInstallmentCalculator.vue");
const ProductCollectionCard = () => import('@/components/collection/ProductCollectionCard');
const ModalClosedShopInfo = () => import("@/components/modals/ModalClosedShopInfo.vue");
const ProductBoxSkeleton = () => import("@/components/skeleton/ProductBoxSkeleton.vue");

import axios from "axios";
import moment from "moment";

const initialData = () => ({
  slickSettings: {
    centerMode: true,
    centerPadding: "20px",
    slidesToShow: 6,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: true,
          centerPadding: "0px",
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 481,
        settings: {
          arrows: true,
          centerPadding: "0px",
          slidesToShow: 2,
        },
      },
    ],
  },
  checkedBrands: [],
  checkedCollections: [],
  checkedCategories: [],
  checkedColors: [],
  checkedSizes: [],
  minPrice: "",
  maxPrice: "",
  products: [],
  highlights: [],
  sort: [{ property: "numOfOrder", direction: "desc" }],
  sortMask: "popular",
  totalResult: 0,
  totalReviewResult: 0,
  currentPage: 1,
  elasticLimit: 20,
  currentPageReview: 1,
  colors: [],
  sizes: [],
  hasResult: true,
  filters: {
    logic: "and",
    filters: [
      {
        property: "price",
        operator: "greater_than_or_equals",
        values: [
          {
            value: 0,
          },
        ],
      },
      {
        property: "price",
        operator: "less_than_or_equals",
        values: [
          {
            value: 100000000,
          },
        ],
      },
    ],
  },
  activeBanner: undefined,
  activeContent: undefined,
  hideAllFilters: false,
  hideBrandsFilter: false,
  hideCategoriesFilter: false,
  hideCollectionsFilter: false,
  hidePriceFilter: false,

  //Nav Bar for shop by brand
  navBarProduct: "active",
  navBarCollection: "",
  navBarReview: "",
  navBarInfo: "",

  brandCollection: [],
  brandReview: [],

  //For Modal Image
  reviewImage: undefined,
  reviewImageIndex: undefined,

  // for modal category size
  categorySize: null,

  // for skeleton load
  // isLoading: true,
  isLoading: {
    sideBar: true,
    productList: true,
    brandCollectionList: true,
    brandReviewList: true,
  },

  //? For Modal Installment Calculator
  installmentCalculator: null,

  //? detailPromo.status (0 = Promo hasn't started, 1 = Promo already started, 2 = Promo already expired)
  detailPromo: null,

  bpExclusiveProduct: null,
});


export default {
  name: "Productlist",
  components: {
    UrlHandler,
    ProductBox,
    ModalCategorySize,
    ModalImage,
    NewArrival,
    ModalInstallmentCalculator,
    ProductCollectionCard,
    ModalClosedShopInfo,
    ProductBoxSkeleton
  },
  metaInfo() {
    return setMetainfo(this.selectedBrand
      ? `${this.selectedBrand.name} Official Store | Harga Terbaik dan Termurah Seindonesia` : null,
      this.selectedBrand?.meta_description,
      this.selectedBrand?.image_url
    )
  },
  data() {
    return Object.assign(initialData(), {
      announcementBanner: null,
      collapseBrands: true,
      collapseCollections: true,
      collapseCategories: true,
      collapseColors: true,
      collapseSize: true,
      feUrl: process.env.VUE_APP_FE_URL,
    });
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    brands() {
      return this.$store.getters.getBrandsList;
    },
    collections() {
      return this.$store.getters.getCollectionsList;
    },
    categories() {
      return this.$store.getters.getCategoriesList;
    },
    selectedBrand() {
      const brandParam = this.$route.params.brand
      return brandParam ? this.brands.find(
        (el) => el.id == brandParam.split("-").pop()
      ) : null
    },
    token() {
      return this.$store.getters.getUserToken;
    },
  },
  watch: {
    "$route.path"() {
      Object.assign(this.$data, initialData());
      this.handleUrl();
    },
    "$route.query"() {
      if (this.navBarReview) {
        this.currentPageReview = this.$route.query.p || 1;
        this.getReviewByBrand();
      } else {
        this.products = initialData().products; // * Set product to initial data
        this.isLoading.productList = true; // * Show skeleton loading for product list
        this.currentPage = this.$route.query.p || 1;
        this.checkSelectedFilter();
        this.getProducts();
      }
    },
    "$store.getters.getResellerData"(resellerData) {
      if (resellerData.status_verify) this.getProducts();
    },
    token() {
      if (this.$route.params.slug == "banner") this.getAllBanner();
    },
  },
  mounted() {
    this.checkSelectedFilter();
    this.handleUrl();
    this.getMiniBanner('productlist');
  },
  methods: {
    getBreadcrumb() {
      if (this.$route.params.slug == "bp-exclusive") return "Whiz Signature";
      else return "Product";
    },
    checkSelectedFilter() {
      const query = this.$route.query;
      if (query.b)
        this.checkedBrands = query.b.split(",");
      if (query.l)
        this.checkedCollections = query.l.split(",");
      if (query.c)
        this.checkedCategories = query.c.split(",");
      if (query.h)
        this.checkedColors = query.h.split(",");
      if (query.s)
        this.checkedSizes = query.s.split(",");
      if (query.from)
        this.minPrice = this.parseFloatSeparator(query.from).toLocaleString("id");
      if (query.to)
        this.maxPrice = this.parseFloatSeparator(query.to).toLocaleString("id");
      if (query.sort) {
        const event = { target: { value: query.sort } };
        this.setSort(event);
      }
    },
    showModalInstallmentCalculator(e) {
      this.installmentCalculator = e;
      this.$bvModal.show('modal-installment-calculator');
    },
    async getPromoDetail() {
      try {
        const promoId = this.$route.params.slug2.split('-').pop();
        const res = await this.$api.cms.getPromotions(promoId)
        if (res.status === 200) {
          this.detailPromo = res.data.data;
          if (res.data.data.status == 1) this.getPromoBannerV2(res.data.data); //* If promo is active
        } 
      } catch (error) {
        console.error(error);
      }
    },
    handleUrl() {
      this.getColors();
      this.getSizes();
      const url = this.$route;
      if (url.name == "ProductListByBrand") {
        if (url.params.brand) {
          this.hideBrandsFilter = true;
          // Start push into history brand
          const selectedBrand = () => {
            if (!this.selectedBrand) return setTimeout(() => selectedBrand(), 100)
            const history = this.$store.getters.getBrandHistory
            const index = history.findIndex(el => el.id == this.selectedBrand.id)
            const push = {
              id: this.selectedBrand.id,
              name: this.selectedBrand.name,
              slug: url.params.brand,
              image: this.selectedBrand.image_url,
            }
            if (index >= 0) {
              history.splice(index, 1)
              history.unshift(push)
            } else {
              history.unshift(push)
              if (history.length > 3) history.pop()
            }
            cookieMan.put("fe_history_brand", JSON.stringify(history))
            this.$store.dispatch("setBrandHistory", history)
          }
          selectedBrand()
          // End push into history brand
          this.getBrandContent();
          this.getHighlightedProduct()
        }
      } else if (url.params.slug == "banner") {
        this.getAllBanner();
      } else if (url.params.slug == "collection") {
        this.getCollectionBanner();
        this.getCollectionContent();
        this.hideCollectionsFilter = true;
      } else if (url.params.slug == "promo") {
        // this.getPromoBanner();
        this.getPromoDetail();
      } 
      else if (url.params.slug == "new-in") {
        if(!this.$route.query.sort) {
          this.sortMask = "latest";
          // this.activeBanner = require('@/assets/img/banner_new_in.jpg')
          this.sort = [{ property: "created_at", direction: "desc" }];
        }
      } else if (url.params.keyWord) {
        //* Do nothing, sort for elastic search is still needed. if the current condition (else if url.params.keyword) is removed, cannot open the current component when search product 
        // this.sort = undefined;
      } else if (url.params.slug == "under5m") {
        this.hidePriceFilter = true;
        this.maxPrice = "5.000.000";
      } else if (url.params.slug == "under15m") {
        this.hidePriceFilter = true;
        this.maxPrice = "15.000.000";
      } else if (url.params.slug == "under25m") {
        this.hidePriceFilter = true;
        this.maxPrice = "25.000.000";
      } else if (url.params.slug == "bp-exclusive") {
        this.bpExclusiveProduct = true;
      }
      // else if (url.params.slug == "redeemable") {
      //   this.hideAllFilters = true;
      //   return this.getProducts();
      // }
      else {
        this.getCategoryBanner();
      }
      this.applyFilter();
    },
    // Start filters logic
    selectAllBrand() {
      this.checkedBrands = [];
    },
    selectAllCollections() {
      this.checkedCollections = [];
    },
    selectAllCategory() {
      this.checkedCategories = [];
    },
    getColors() {
      axios
        .get(this.apiUrl + "users/cms/color", {
          headers: { api_key: this.apiKey },
        })
        .then((response) => this.setColors(response.data.data))
        .catch((error) => console.log(error));
    },
    setColors(data) {
      this.colors = data;
    },
    selectAllColors() {
      this.checkedColors = [];
    },
    getSizes() {
      axios
        .get(this.apiUrl + "users/cms/size", {
          headers: { api_key: this.apiKey },
        })
        .then((response) => this.setSizes(response.data.data))
        .catch((error) => console.log(error));
    },
    setSizes(data) {
      this.sizes = data;
    },
    selectAllSizes() {
      this.checkedSizes = [];
    },
    // For Product List Per Brand Only
    changeNavbarByBrand(navTo, page) {
      this.navBarProduct = "";
      this.navBarCollection = "";
      this.navBarReview = "";
      this.navBarInfo = "";

      if (navTo == "product") {
        this.navBarProduct = "active";
        this.currentPage = page || 1;
        this.getProducts();
      } else if (navTo == "collection") {
        this.navBarCollection = "active";
        this.getCollectionByBrand();
      }  else if (navTo == "review") {
        this.navBarReview = "active";
        this.currentPageReview = page || 1;
        this.getReviewByBrand();
      } else if (navTo == "info") {
        this.navBarInfo = "active";
      }

      //Reset Pagination for product navbar & review navbar in shop by brand
      if ((navTo == "product" || navTo == "review") && this.$route.query.p != 1)
        this.$router.push({
          query: Object.assign({}, this.$route.query, { p: 1 }),
        });
      //Set router query without pagination for info navbar in shop by brand
      else if (navTo == "info" && this.$route.query.p) this.$router.push(this.$route.path);
    },
    scrollIntoOpTime() {
      setTimeout(() => {
        const elHeaderOffset = document.getElementsByClassName("header")[0].offsetHeight;
        const elOpTime = document.getElementById('merchant-op-time');
        if (!elOpTime && !elHeaderOffset) return
        var elementPosition = elOpTime.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - elHeaderOffset - 15;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        })
      }, 100);
    },
    priceSeparator(model) {
      if (/^[0-9.,]+$/.test(this.$data[model])) this.$data[model] = this.parseFloatSeparator(this.$data[model]).toLocaleString("id")
      else this.$data[model] = this.$data[model].substring(0, this.$data[model].length - 1);
    },
    parseFloatSeparator(value) {
      return value ? parseFloat(value.replace(/\./g, "")): value;
    },
    applyFilter(event) {
      //* Handling if min price >= max price, set max price to null
      if (this.parseFloatSeparator(this.minPrice) >= this.parseFloatSeparator(this.maxPrice)) this.maxPrice = ""; 
      this.filters = {
        logic: "and",
        filters: [],
      };
      // Start query & logic builder
      const query = {};
      if (this.$route.params.keyWord) {
        this.filters.filters.push({
          property: "name",
          operator: "like",
          values: [{ value: this.$route.params.keyWord }],
        });
      } else if (this.$route.params.brand) {
        const value = this.$route.params.brand.split("-").pop();
        this.filters.filters.push({
          property: "brand.id",
          operator: "equals",
          values: [{ value }],
        });
      } else if (this.hideCategoriesFilter) {
        const value = this.$route.params.slug3.split("-").pop();
        this.filters.filters.push({
          property: "category.id",
          operator: "equals",
          values: [{ value }],
        });
      } else if (/promo|banner|additional-category/gi.test(this.$route.params.slug)) {
        const value = [this.$route.params.slug, this.$route.params.slug2].join(
          "/"
        );
        this.filters.filters.push({
          property: "slug",
          operator: "equals",
          values: [{ value }],
        });
      } else if (this.hideCollectionsFilter) {
        const value = this.$route.params.slug2.split("-").pop();
        this.filters.filters.push({
          property: "collections.id",
          operator: "equals",
          values: [{ value }],
        });
      } 
      if (this.hidePriceFilter) {
        this.filters.filters.push({
          property: "price",
          operator: "less_than_or_equals",
          values: [
            {
              value: parseFloat(this.maxPrice.replace(/\./g, "")),
            },
          ],
        });
      } else if (!this.hidePriceFilter) {
        const minPrice = this.parseFloatSeparator(this.minPrice);
        const maxPrice = this.parseFloatSeparator(this.maxPrice);
        if (minPrice && minPrice > 0) {
          query.from = minPrice.toString();
          this.filters.filters.push({
            property: "price",
            operator: "greater_than_or_equals",
            values: [
              {
                value: minPrice,
              },
            ],
          });
        }
        //* Add handling if minPrice > maxPrice, don't add / push filter for maxPrice
        // if (maxPrice < 100000000) {
        if (maxPrice && maxPrice > 0) {
          query.to = maxPrice.toString();
          this.filters.filters.push({
            property: "price",
            operator: "less_than_or_equals",
            values: [
              {
                value: maxPrice,
              },
            ],
          });
        }
      }
      if (this.checkedBrands.length > 0) {
        const filter = {
          property: "brand.id",
          operator: "in",
          values: [],
        };
        const paramId = [];
        for (let i = 0; i < this.checkedBrands.length; i++) {
          paramId.push(this.checkedBrands[i]);
          filter.values.push({ value: this.checkedBrands[i] });
        }
        query.b = paramId.join(",");
        this.filters.filters.push(filter);
      }
      if (this.checkedCollections.length > 0) {
        const filter = {
          property: "collections.id",
          operator: "in",
          values: [],
        };
        const paramId = [];
        for (let i = 0; i < this.checkedCollections.length; i++) {
          paramId.push(this.checkedCollections[i]);
          filter.values.push({ value: this.checkedCollections[i] });
        }
        query.l = paramId.join(",");
        this.filters.filters.push(filter);
      }
      if (this.checkedCategories.length > 0) {
        const filter = {
          property: "category.id",
          operator: "in",
          values: [],
        };
        const paramId = [];
        for (let i = 0; i < this.checkedCategories.length; i++) {
          paramId.push(this.checkedCategories[i]);
          filter.values.push({ value: this.checkedCategories[i] });
        }
        query.c = paramId.join(",");
        this.filters.filters.push(filter);
      }
      if (this.checkedColors.length > 0) {
        const filter = {
          property: "colors.id",
          operator: "in",
          values: [],
        };
        const paramId = [];
        for (let i = 0; i < this.checkedColors.length; i++) {
          paramId.push(this.checkedColors[i]);
          filter.values.push({ value: this.checkedColors[i] });
        }
        query.h = paramId.join(",");
        this.filters.filters.push(filter);
      }
      if (this.checkedSizes.length > 0) {
        const filter = {
          property: "sizes.id",
          operator: "in",
          values: [],
        };
        const paramId = [];
        for (let i = 0; i < this.checkedSizes.length; i++) {
          paramId.push(this.checkedSizes[i]);
          filter.values.push({ value: this.checkedSizes[i] });
        }
        query.s = paramId.join(",");
        this.filters.filters.push(filter);
      }
      if (this.$route.query.sort) query.sort = this.$route.query.sort;
      if (event) this.currentPage = 1;
      else if (this.$route.query.p)
        this.currentPage = query.p = this.$route.query.p;
      // End query & logic builder
      if (JSON.stringify(this.sortObject(this.$route.query)) === JSON.stringify(this.sortObject(query)))
        this.getProducts();
      else this.$router.push({ query: Object.assign({}, query) });
    },
    // End filters logic
    setSort(type) {
      if (type.target.value == "lowest_price") {
        this.sort = [{ property: "priceDisplay", direction: "asc" }];
      } else if (type.target.value == "highest_price") {
        this.sort = [{ property: "priceDisplay", direction: "desc" }];
      } else if (type.target.value == "name_desc") {
        this.sort = [{ property: "slug", direction: "desc" }];
      } else if (type.target.value == "name_asc") {
        this.sort = [{ property: "slug", direction: "asc" }];
      } else if (type.target.value == "latest") {
        this.sort = [{ property: "created_at", direction: "desc" }];
      } else if (type.target.value == "discount") {
        this.sort = [{ property: "discount", direction: "desc" }];
      } else if (type.target.value == "popular") {
        this.sort = [{ property: "numOfOrder", direction: "desc" }];
      }
      this.sortMask = type.target.value
      if (this.$route.query?.sort == type.target.value) return //* Prevent duplicate and redudant route if value 'this.$route.query.sort' already same as 'type.target.value'
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          sort: type.target.value,
        }),
      });
    },
    async getProducts() {
      try {
        const res = await this.$api.product.getProductList(this.currentPage - 1, this.elasticLimit, this.sort, this.filters, null, this.bpExclusiveProduct)
        if (res.status === 200) {
          this.totalResult = res.data.meta.total
          if (this.totalResult == 0) this.hasResult = false;
          else if (this.totalResult - this.currentPage * this.elasticLimit <= -this.elasticLimit) {
            this.$router.push({query: Object.assign({}, this.$route.query, { p: 1 })});
            return
          } else this.hasResult = true;
          this.setProduct(res.data);
          // for viewing product list that has a promotion
          if(this.$route.params.slug == "promo") this.firebaseViewPromotion(res.data.data.result)
          if (!this.$route.path.includes("/search/")) {
            setTimeout(() => {
              window.prerenderReady = true
            }, 2000)
          } 
        } else {
          this.hasResult = false;
          // toaster.make(res.data.message, 'danger') //* Getting internal serveer error 500 promo product list with invalid promo_id, res.data is undefined
        }
        this.isLoading.sideBar = false
        this.isLoading.productList = false
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    setProduct(data) {
      // for redeemable prouct
      // if (this.$route.params.slug == "redeemable") this.products = data["data"];
      // else
      this.products = data["data"]["result"];
    },
    firebaseViewPromotion(data) {
      const items = data.map(data => ({
        item_id: data.id.toString(),
        item_name: data.name,
        item_brand: data.brand_name,
        price: data.price_display,
        quantity: data.total_stock,
      }));

      this.$firebaseLogEvent("view_promotion", {
        promotion_name: this.$route.params.slug2,
        items: items,
      });
      
    },
    async getMiniBanner(type) {
      try {
        const res = await this.$api.cms.getMiniBanner(type)
        if (res.status === 200) this.announcementBanner = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    getCategoryBanner() {
      if (!/bestseller|all|additional-category/.test(this.$route.params.slug)) {
        this.hideCategoriesFilter = true;
        try {
          const processedSlug3 = this.$route.params.slug3.substr(
            0,
            this.$route.params.slug3.lastIndexOf("-")
          );
          const url = [
            this.$route.params.slug,
            this.$route.params.slug2,
            processedSlug3,
          ].join("-");
          axios
            .get(this.apiUrl + "users/cms/category_parent/" + url, {
              headers: { api_key: this.apiKey },
            })
            .then((res) => {
              this.activeBanner = {
                category_img: res.data.data.category_3_banner_url,
                img_alt: res.data.data.category_3_name
              };
            });
        } catch (e) {
          console.error(e);
          this.$router.push("/");
        }
      }
    },
    async getAllBanner() {
      try {
        const res = await cmsService.getAllBanner();
        if (res.status === 200) {
          const slugId = this.$route.params.slug2.split("-").pop();
          const data = res.data.data.find(
            (el) => el.slug.split("-").pop() == slugId
          );
          this.activeBanner = {
            img: data.image_url,
            mobile_img: data.image_url_mobile,
          };
        }
      } catch (e) {
        console.error(e);
      }
    },
    //* Find promo data from $store.getters.getPromoList 
    getPromoBanner() {
      const promotions = this.$store.getters.getPromoList;
      if (promotions.length) {
        const data = promotions.find(el => el.slug == this.$route.params.slug + '/' + this.$route.params.slug2)
        if (!data) return
        this.activeBanner = {
          img: data.image_src,
          mobile_img: data.image_responsive_src,
          img_alt: data.image_description
        }
      } else setTimeout(() => this.getPromoBanner(), 100)
    },
    //* Use response data from getPromoDetail()
    getPromoBannerV2(data) {
      this.activeBanner = {
        img: data.image_src,
        mobile_img: data.image_responsive_src,
        img_alt: data.image_description
      }
    },
    getCollectionBanner() {
      if (this.collections.length)  {
        const data = this.collections.find((el) => el.id == this.$route.params.slug2.split("-").pop());
        if (!data) return
        this.activeBanner = {
          img: data.image,
          mobile_img: data.image_mobile,
          img_alt: `${data.brand_name} ${data.name}${!/collection/i.test(data.brand_name) ? ` Collection` : ''}`
        };
      } else setTimeout(() => this.getCollectionBanner(), 100) 
    },
    getCollectionContent() {
      if (this.collections.length)  {
        const data = this.collections.find((el) => el.id == this.$route.params.slug2.split("-").pop());
        if (!data) return
        this.activeContent = {
          desktop: data.content,
          mobile: data.content_mobile,
        };
      } else setTimeout(() => this.getCollectionContent(), 100) 
    },
    getBrandContent() {
      if (this.brands.length)  {
        const data = this.brands.find((el) => el.id == this.$route.params.brand.split("-").pop());
        if (!data) return
        this.activeContent = {
          desktop: data.content,
          mobile: data.content_mobile,
        };
      } else setTimeout(() => this.getBrandContent(), 100) 
    },
    async getHighlightedProduct() {
      const brand = this.selectedBrand
      if (!brand) return setTimeout(() => this.getHighlightedProduct(), 100)
      try {
        const res = await this.$api.cms.getBrandHighlights(brand.id)
        if (res.status === 200) this.highlights = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async getCollectionByBrand() {
      this.isLoading.brandCollectionList = true;
      try {
        this.brandCollection = await this.$store.getters.getBrandsList.find(el => el.id == this.$route.path.split("-").pop()).product_collections;
        this.isLoading.brandCollectionList = false;
      } catch (error) {
        this.isLoading.brandCollectionList = false;
        console.error(error);
      }
    },
    async getReviewByBrand() {
      this.isLoading.brandReviewList = true;
      try {
        const res = await this.$api.user.getProductReviewBrand({
          brandid: this.selectedBrand.id,
          offset: this.currentPageReview - 1,
          limit: 5,
        })
        if (res.status === 200) {
          this.totalReviewResult = res.data.meta.total;
          this.brandReview = res.data.data;
        }
        else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoading.brandReviewList = false;
      } catch (error) {
        this.isLoading.brandReviewList = false;
        console.error(error);
      }
    },
    // getReviewByBrand() {
    //   axios
    //     .get(
    //       this.apiUrl +
    //         "users/productreview/list_brand?brandid=" +
    //         this.selectedBrand.id +
    //         "&offset=" +
    //         (this.currentPageReview - 1) +
    //         "&limit=5",
    //       {
    //         headers: {
    //           api_key: this.apiKey,
    //           "Content-type": "application/json",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       this.totalReviewResult = res.data.meta.total;
    //       if (this.totalReviewResult == 0) this.hasResult = false;
    //       else if (
    //         this.totalReviewResult > 0 &&
    //         this.totalReviewResult - this.currentPageReview * 5 <= -5
    //       ) {
    //         this.$router.push({
    //           query: Object.assign({}, this.$route.query, { p: 1 }),
    //         });
    //         return;
    //       } else this.hasResult = true;
    //       this.setReviewByBrand(res.data.data);
    //     });
    // },
    // setReviewByBrand(data) {
    //   this.brandReview = data;
    // },
    //For opening image modal on navbar review in shop by brand
    sendDataToModal(items, index) {
      this.reviewImage = items;
      this.reviewImageIndex = index;
    },
    pageChange(page) {
      // document.getElementById("sort").scrollIntoView({block: 'center'})
      window.scrollTo(0, 0);
      this.$router.push({
        query: Object.assign({}, this.$route.query, { p: page }),
      });
    },
    setFocusSearch() {
      document.getElementById("search-form").focus()
    },
    formatDate(value) {
      return formatter.todayYesterday(value, "DD MMMM YYYY")
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/default-image.png");
      img.target.style.width = "100px";
    },
    getNoResultText() {
      const route = this.$route.path;
      if (route.includes("/shop-by-brands/")) return "Oops, brand yang kamu cari tidak ada";
      if (route.includes("/collection/")) return "Oops, koleksi produk yang kamu cari tidak ada";
      if (route.includes("/promo/")) return "Oops, promo yang kamu cari tidak ada.";
      else return "Oops, kategori produk yang kamu ";
    },
    getTodayOperationalTime(data) {
      //* Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
      const currentDate = new Date().getDay();
      // Notes: el.id from data are 1 (Monday) - 7 (Sunday)
      if (currentDate == 0) return data.find(el => el.id == 7);
      else return data.find(el => el.id == currentDate);
    },
    setOperationalTimeText(currOpTime) {
      if (!currOpTime.open && !currOpTime.closed) return "Libur";
      else if (currOpTime.open == "00:00" && currOpTime.closed == "23:59") return "Buka 24 Jam";
      else return `${currOpTime.open} - ${currOpTime.closed} WIB`
    },
    formatEndDateClose(value) {
      moment.locale("id"); // Set date locale to indonesia
      return value ? moment.unix(value/1000).add(1, "days").format("DD MMMM YYYY") : value;
    },
    hideClosedShopInfo() {
      document.querySelector("#store-closed-shop-info-brand").remove();
    },
    //* Method for sort object keys with alphabetical order
    sortObject(data) {
      return Object.keys(data).sort().reduce((acc, key) => { 
          acc[key] = data[key];
          return acc;
      }, {});
    },
    resetFilter() {
      this.checkedBrands = [];
      this.checkedCollections = [];
      this.checkedCategories = [];
      this.checkedColors = [];
      this.checkedSizes = [];
      this.minPrice = "";
      this.maxPrice = "";
    },
    resetFilterDisabledState() {
      return [this.checkedBrands, this.checkedCollections, this.checkedCategories, this.checkedColors, this.checkedSizes].every(el => el.length == 0) && [this.minPrice, this.maxPrice].every(el => el == "");
    },
    setProductImgAltText(product) {
      return `${product.brand_name} ${product.name}`
    },
    setHighlightProductImgAltText(highlight) {
      return highlight.product?.name ? `${highlight.brand.name} ${highlight.product.name}` : '';
    },
  },
};
</script>

<style scoped>
.product-box:not(.product-box-skeleton):hover {
  border: 1px solid #d89700;
}
#min-price-separator,
#max-price-separator {
  font-size: 10.5px;
}
.d-grid {
  display: grid;
  grid-template-columns: repeat(2, calc(100% / 2));
}
.cell-full {
  grid-column: auto/span 2;
}

@media (min-width: 992px) {
  .d-grid {
    grid-template-columns: repeat(4, calc(100% / 4));
  }
  .cell-full {
    grid-column: auto/span 4;
  }
}

.cell-2x2 {
  grid-column: auto/span 2;
  grid-row: auto/span 2;
  padding: .25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-filter {
  position: sticky;
  top: 64px;
  padding: .5rem 0;
  /* background: linear-gradient(to top, transparent, #fff 50%); */
  background: #fff;
  color: #fff;
  text-align: center;
  font-size: .9em;
  z-index: 4;
}
.floating-filter.more {
  top: 92px;
}
.floating-filter-body {
  display: flex;
  border-radius: 0;
  background: #d89700;
  padding: 0 .25rem;
  margin: 0 1rem;
}
.floating-filter-body > div {
  position: relative;
  padding: .5rem 1rem;
  width: 100%;
}
.floating-filter-body > div select {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
